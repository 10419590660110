import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { Controller, useForm } from 'react-hook-form';
import { Button, InputAdornment, TextField } from '@mui/material';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { IChallengeForm, IChallengeLevel, Level } from '../../types';
import { FormLayout, StyledImg, StyledPaper } from '../../styled';
import { pageSize } from '../../../../utils/constants';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getAchievementsFormSelector,
  getChallengesLoadMoreSelector,
  getPageSelector,
  getAchievementSelector,
} from '../../selector';
import { getAllAchievements } from '../../service';
import { setPage, clearAchievements } from '../../slices';
import { fetchJson } from '../../utils';
import 'react-datepicker/dist/react-datepicker.css';
import ScrollSelect from '../../../../components/ScrollSelect';

interface IFromProps {
  form?: IChallengeForm;
  onSubmit: (data: IChallengeForm) => void;
  oldImage?: string;
}

const Form = memo(({ form, onSubmit, oldImage }: IFromProps) => {
  const [url, setUrl] = useState('');
  const [json, setJson] = useState(null);
  const [startDate, setStartDate] = useState<Date | null | undefined>(
    form?.startDate,
  );
  const [finishDate, setFinishDate] = useState<Date | null | undefined>(
    form?.finishDate,
  );
  const [isDatesTouched, setIsDatesTouched] = useState(false);
  const dispatch = useAppDispatch();
  const achievementsForm = useAppSelector(getAchievementsFormSelector);
  const achievements = useAppSelector(getAchievementSelector);
  const loadMoreStatus = useAppSelector(getChallengesLoadMoreSelector);
  const page = useAppSelector(getPageSelector);

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
    control,
    watch,
  } = useForm<IChallengeForm>({
    values: {
      name: form?.name,
      nameRu: form?.nameRu,
      description: form?.description,
      descriptionRu: form?.descriptionRu,
      files: form?.files,
      shortDescription: form?.shortDescription,
      shortDescriptionRu: form?.shortDescriptionRu,
      level: form?.level,
      achievementId: form?.achievementId,
    },
  });
  const statusField = () => (form ? { shrink: true } : {});
  const options = useMemo<IChallengeLevel[]>(
    () =>
      Object.values(Level).map((level) => ({
        label: level,
        value: level,
      })),
    [form?.level],
  );
  const achievement = watch('achievementId');
  const files = watch('files');

  const getAchievements = useCallback(() => {
    dispatch(getAllAchievements({ page, pageSize }));
  }, [page, dispatch]);

  const cleanAchievementsOnClose = useCallback(() => {
    dispatch(clearAchievements());
  }, [dispatch]);

  const handleScroll = useCallback(
    (pageNum: number) => {
      dispatch(setPage(pageNum));
    },
    [dispatch],
  );

  const getJsonData = useCallback(async () => {
    if (url) {
      const response = await fetchJson(url);
      setJson(response);
    } else {
      setJson(null);
    }
  }, [url]);

  useEffect(() => {
    if (loadMoreStatus) {
      getAchievements();
    }
  }, [page, loadMoreStatus]);

  useEffect(() => {
    if (achievement) {
      const currentAchiev = achievements.find(
        (achiv) => achiv.id === achievement.value,
      );
      if (currentAchiev?.image) {
        setUrl(currentAchiev.image.url);
      }
    } else {
      setUrl('');
    }
  }, [achievement, achievements]);

  useEffect(() => {
    getJsonData();
  }, [url, getJsonData]);

  const onDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setFinishDate(end);
    setIsDatesTouched(true);
  };
  const submit = (values: IChallengeForm) => {
    onSubmit({
      ...values,
      startDate,
      finishDate,
    });
  };

  return (
    <StyledPaper elevation={3}>
      <FormLayout onSubmit={handleSubmit(submit)}>
        <div>
          <TextField
            label="Name"
            fullWidth
            {...register('name')}
            InputLabelProps={statusField()}
          />
          <span>{errors.name && errors.name.message}</span>
        </div>
        <div>
          <TextField
            label="Name Ru"
            fullWidth
            {...register('nameRu')}
            InputLabelProps={statusField()}
          />
          <span>{errors.nameRu && errors.nameRu.message}</span>
        </div>
        <div>
          <TextField
            label="Description"
            fullWidth
            multiline
            {...register('description')}
            InputLabelProps={statusField()}
          />
          <span>{errors.description && errors.description.message}</span>
        </div>
        <div>
          <TextField
            label="Description Ru"
            fullWidth
            multiline
            {...register('descriptionRu')}
            InputLabelProps={statusField()}
          />
          <span>{errors.descriptionRu && errors.descriptionRu.message}</span>
        </div>
        <div>
          <TextField
            label="Short description"
            fullWidth
            {...register('shortDescription')}
            InputLabelProps={statusField()}
          />
          <span>
            {errors.shortDescription && errors.shortDescription.message}
          </span>
        </div>
        <div>
          <TextField
            label="Short description Ru"
            fullWidth
            {...register('shortDescriptionRu')}
            InputLabelProps={statusField()}
          />
          <span>
            {errors.shortDescriptionRu && errors.shortDescriptionRu.message}
          </span>
        </div>
        <DatePicker
          selected={startDate}
          onChange={onDateChange}
          startDate={startDate}
          endDate={finishDate}
          selectsRange
          popperPlacement="bottom-end"
          showPopperArrow={false}
          customInput={
            <TextField
              fullWidth
              InputLabelProps={statusField()}
              label="Date Range"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        <div>
          <label htmlFor="level">Level</label>
          <Controller
            control={control}
            name="level"
            render={({ field }) => (
              <Select {...field} isMulti options={options} />
            )}
          />
        </div>
        <div>
          <label htmlFor="file">Challenge image</label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: 'image/jpeg, image/png' }}
            {...register('files')}
          />
          <span>{errors.files && errors.files.message}</span>
          {oldImage && !files?.length ? (
            <StyledImg src={oldImage} alt="" />
          ) : null}
        </div>
        <div>
          <label htmlFor="achievementId">Achievement</label>
          <Controller
            control={control}
            name="achievementId"
            render={({ field }) => (
              <ScrollSelect
                {...field}
                options={achievementsForm}
                handleOpen={getAchievements}
                handleClose={cleanAchievementsOnClose}
                page={page}
                setPage={handleScroll}
                loadMore={loadMoreStatus}
              />
            )}
          />
          <div>{errors.achievementId && errors.achievementId.message}</div>
          {json ? (
            <Lottie
              animationData={json}
              loop
              style={{ width: '160px', height: '160px' }}
            />
          ) : null}
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!(isDirty || isDatesTouched) || !isValid}
        >
          {form ? 'Update challenge' : 'Create challenge'}
        </Button>
      </FormLayout>
    </StyledPaper>
  );
});

export default Form;
