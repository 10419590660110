import React, { FC } from 'react';
import { UseFormResetField, useForm } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Button,
  TextField,
  CircularProgress,
} from '@mui/material';
import { ExerciseFormField } from '../styles';
import { TrainerFormLayout, TrainerFormField } from '../../TrainerPage/styles';
import { CreateExerciseRequest } from '../service';
import { StyledImg } from '../../ChallengesPage/styled';

export interface IExerciseForm extends CreateExerciseRequest {
  previewImageFiles?: FileList;
  affirmationImageFiles?: FileList;
  affirmationImageFilesRu?: FileList;
  videoFiles?: FileList;
  videoFilesRu?: FileList;
  duration?: number;
  durationRu?: number;
}

interface IExerciseFormComponentProps {
  onSubmit: (
    data: CreateExerciseRequest,
    resetField: UseFormResetField<IExerciseForm>,
  ) => void;
  form?: IExerciseForm;
  oldAffirmationImage?: string;
  oldAffirmationImageRu?: string;
  oldPreview?: string;
  oldVideoPreview?: string;
  oldVideoPreviewRu?: string;
  type: string;
  isLoading: boolean;
}

const ExerciseForm: FC<IExerciseFormComponentProps> = ({
  onSubmit,
  form,
  type,
  oldPreview,
  oldVideoPreview,
  oldVideoPreviewRu,
  oldAffirmationImage,
  oldAffirmationImageRu,
  isLoading,
}) => {
  const {
    handleSubmit,
    register,
    formState: { isDirty, errors },
    getValues,
    watch,
    resetField,
  } = useForm<IExerciseForm>({
    values: {
      title: form?.title || '',
      titleRu: form?.titleRu || '',
      description: form?.description || '',
      descriptionRu: form?.descriptionRu || '',
      isDraft: form ? form.isDraft : true,
      showTitle: form ? form.showTitle : true,
      position: form?.position,
      duration: form?.duration,
      durationRu: form?.durationRu,
    },
  });
  const previewImageFiles = watch('previewImageFiles');
  const affirmationImageFiles = watch('affirmationImageFiles');
  const affirmationImageFilesRu = watch('affirmationImageFilesRu');

  const statusField = () => (form ? { shrink: true } : {});
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        minWidth: 345,
        maxWidth: 600,
      }}
    >
      <TrainerFormLayout
        onSubmit={handleSubmit((data) => {
          onSubmit(data, resetField);
          // resetField('videoFiles');
        })}
      >
        <TrainerFormField>
          <TextField
            label="Title"
            fullWidth
            {...register('title', { required: 'This field is required' })}
            InputLabelProps={statusField()}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
        </TrainerFormField>

        <TrainerFormField>
          <TextField
            label="Title Ru"
            fullWidth
            {...register('titleRu', { required: 'This field is required' })}
            InputLabelProps={statusField()}
            error={!!errors.titleRu}
            helperText={errors.titleRu?.message}
          />
        </TrainerFormField>

        <TrainerFormField>
          <TextField
            label="Description"
            fullWidth
            multiline
            {...register('description', {
              required: 'This field is required',
            })}
            InputLabelProps={statusField()}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </TrainerFormField>

        <TrainerFormField>
          <TextField
            label="Description Ru"
            fullWidth
            multiline
            {...register('descriptionRu', {
              required: 'This field is required',
            })}
            InputLabelProps={statusField()}
            error={!!errors.descriptionRu}
            helperText={errors.descriptionRu?.message}
          />
        </TrainerFormField>

        <TrainerFormField>
          <TextField
            label="Position"
            fullWidth
            type="number"
            {...register('position', { min: 1 })}
            InputProps={{ inputProps: { min: 1 } }}
            InputLabelProps={statusField()}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
        </TrainerFormField>

        <ExerciseFormField>
          <label htmlFor="previewImageFiles">Exercise image</label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: 'image/jpeg, image/png' }}
            {...register('previewImageFiles', {
              ...(!oldPreview && { required: 'This field is required' }),
            })}
            error={!!errors.previewImageFiles}
            helperText={errors.previewImageFiles?.message}
          />
          {oldPreview && !previewImageFiles?.length ? (
            <StyledImg src={oldPreview} alt="" />
          ) : null}
        </ExerciseFormField>

        <ExerciseFormField>
          <label htmlFor="affirmationImageFiles">
            Exercise affirmation image
          </label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: 'image/jpeg, image/png' }}
            {...register('affirmationImageFiles')}
            error={!!errors.affirmationImageFiles}
            helperText={errors.affirmationImageFiles?.message}
          />
          {oldAffirmationImage && !affirmationImageFiles?.length ? (
            <StyledImg src={oldAffirmationImage} alt="" />
          ) : null}
        </ExerciseFormField>

        <ExerciseFormField>
          <label htmlFor="affirmationImageFilesRu">
            Exercise affirmation image Ru
          </label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: 'image/jpeg, image/png' }}
            {...register('affirmationImageFilesRu')}
            error={!!errors.affirmationImageFilesRu}
            helperText={errors.affirmationImageFilesRu?.message}
          />
          {oldAffirmationImageRu && !affirmationImageFilesRu?.length ? (
            <StyledImg src={oldAffirmationImageRu} alt="" />
          ) : null}
        </ExerciseFormField>

        <ExerciseFormField>
          <FormControlLabel
            {...register('isDraft')}
            control={<Checkbox defaultChecked={getValues('isDraft')} />}
            label="Is draft"
          />
          <FormControlLabel
            {...register('showTitle')}
            control={<Checkbox defaultChecked={getValues('showTitle')} />}
            label="Show title"
          />
        </ExerciseFormField>

        <ExerciseFormField>
          <label htmlFor="videoFiles">Exercise video preview:</label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
            {...register('videoFiles', {
              ...(!oldVideoPreview && { required: 'This field is required' }),
            })}
            error={!!errors.videoFiles}
            helperText={errors.videoFiles?.message}
          />
          {oldVideoPreview ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              controls
              style={{ width: '320', height: '240' }}
              src={oldVideoPreview}
            />
          ) : null}
        </ExerciseFormField>

        <ExerciseFormField>
          <TextField
            label="Video Duration"
            fullWidth
            type="number"
            {...register('duration', {
              required: 'This field is required',
              min: 1,
            })}
            InputLabelProps={statusField()}
            InputProps={{ inputProps: { min: 1 } }}
            error={!!errors.duration}
            helperText={errors.duration?.message}
          />
        </ExerciseFormField>

        <ExerciseFormField>
          <label htmlFor="videoFilesRu">Exercise video preview Ru:</label>
          <TextField
            fullWidth
            type="file"
            inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
            {...register('videoFilesRu', {
              ...(!oldVideoPreviewRu && { required: 'This field is required' }),
            })}
            error={!!errors.videoFilesRu}
            helperText={errors.videoFilesRu?.message}
          />
          {oldVideoPreviewRu ? (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              controls
              style={{ width: '320', height: '240' }}
              src={oldVideoPreviewRu}
            />
          ) : null}
        </ExerciseFormField>

        <ExerciseFormField>
          <TextField
            label="Video Duration Ru"
            fullWidth
            type="number"
            {...register('durationRu', {
              required: 'This field is required',
              min: 1,
            })}
            InputLabelProps={statusField()}
            InputProps={{ inputProps: { min: 1 } }}
            error={!!errors.durationRu}
            helperText={errors.durationRu?.message}
          />
        </ExerciseFormField>

        {type === 'update' ? (
          <Button
            size="medium"
            variant="contained"
            aria-label="Update exercise"
            type="submit"
            title="Update exercise"
            disabled={!isDirty || isLoading}
          >
            {isLoading ? <CircularProgress size={20} /> : 'Update exercise'}
          </Button>
        ) : (
          <Button
            size="medium"
            variant="contained"
            disabled={!isDirty || isLoading}
            aria-label="Create exercise"
            type="submit"
            title="Create exercise"
          >
            {isLoading ? <CircularProgress size={20} /> : 'Create exercise'}
          </Button>
        )}
      </TrainerFormLayout>
    </Paper>
  );
};

export default ExerciseForm;
