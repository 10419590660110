import { RootState } from '../../../store';

export const getDaysLoadingSelector = (state: RootState) =>
  state.challengeDay.isLoading;
export const getIsImageLoadingSelector = (state: RootState) =>
  state.challengeDay.isImageLoading;
export const getIsVideoLoadingSelector = (state: RootState) =>
  state.challengeDay.isVideoLoading;
export const getChallengeDaysSelector = (state: RootState) =>
  state.challengeDay.challengeDays;
export const getChallengeDaySelector = (state: RootState) =>
  state.challengeDay.day;
export const getChallengeDayImageSelector = (state: RootState) =>
  state.challengeDay.imageUrl;
export const getChallengeDayVideoSelector = (state: RootState) =>
  state.challengeDay.videoUrl;
export const getChallengeDayVideoRuSelector = (state: RootState) =>
  state.challengeDay.videoUrlRu;
