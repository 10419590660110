import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import {
  createAchievement,
  getAchievement,
  updateAchievement,
  uploadImage,
} from '../service';
import {
  IAchievementState,
  ICreateAchievementsRequest,
  IUploadFileResponse,
} from '../types';
import { IAchievement } from '../../Achievements/types';

const initialState: IAchievementState = {
  isLoading: false,
  achievement: null,
};

export const achievementSlice = createSlice({
  name: 'achievement',
  initialState,
  reducers: {
    clearState: (state) => {
      state.achievement = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      createAchievement.fulfilled,
      (state, { payload }: PayloadAction<IAchievement>) => {
        toast.success(ToastMessage.CREATE_ACHIEVEMENT_SUCCESS);
        state.isLoading = false;
        state.achievement = payload;
      },
    );
    builder.addCase(createAchievement.rejected, (state) => {
      toast.error(ToastMessage.CREATE_ACHIEVEMENT_ERROR);
      state.isLoading = false;
    });
    builder.addCase(createAchievement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      updateAchievement.fulfilled,
      (state, { payload }: PayloadAction<ICreateAchievementsRequest>) => {
        toast.success(ToastMessage.UPDATE_ACHIEVEMENT_SUCCESS);
        state.isLoading = false;
        if (state.achievement) {
          state.achievement.name = payload.name;
          state.achievement.nameRu = payload.nameRu;
          state.achievement.description = payload.description;
          state.achievement.descriptionRu = payload.descriptionRu;
        }
      },
    );
    builder.addCase(updateAchievement.rejected, (state) => {
      toast.error(ToastMessage.UPDATE_ACHIEVEMENT_ERROR);
      state.isLoading = false;
    });
    builder.addCase(updateAchievement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getAchievement.fulfilled,
      (state, { payload }: PayloadAction<IAchievement>) => {
        state.achievement = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(getAchievement.rejected, (state) => {
      state.isLoading = false;
      toast.error(ToastMessage.GET_ACHIEVEMENT_ERROR);
    });
    builder.addCase(getAchievement.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      uploadImage.fulfilled,
      (state, { payload }: PayloadAction<IUploadFileResponse>) => {
        if (payload.url && state.achievement?.image) {
          state.achievement.image.url = payload.url;
          state.achievement.image.path = payload.path;
          toast.success(ToastMessage.UPLOAD_ACHIEVEMENT_IMAGE_SUCCESS);
        }
        state.isLoading = false;
      },
    );
    builder.addCase(uploadImage.rejected, (state) => {
      toast.error(ToastMessage.UPLOAD_ACHIEVEMENT_IMAGE_ERROR);
      state.isLoading = false;
    });
    builder.addCase(uploadImage.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { clearState } = achievementSlice.actions;
export default achievementSlice.reducer;
