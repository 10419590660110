import { AxiosError, ResponseType } from 'axios';
import { toast } from 'react-toastify';
import { getErrorMessage } from '../../utils/helpers';
import { IErrorResponse } from '../../types';
import axiosApiInstance from './interceptors';

interface IPostOptions {
  path: string;
  data: { [key: string]: any };
  headers?: { [key: string]: string };
}

interface IAxiosResponse {
  [key: string]: any;
}

export default async function post(
  options: IPostOptions,
  contentType: string = 'application/json',
  responseType: ResponseType = 'json',
  params?: any,
): Promise<any> {
  const { path, data = {}, headers = {} } = options;
  try {
    const res: IAxiosResponse = await axiosApiInstance.post<IAxiosResponse>(
      path,
      data,
      {
        headers: {
          'content-type': contentType,
          ...headers,
        },
        responseType,
        params,
      },
    );
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data;
  } catch (error: any) {
    if (error?.response?.data) {
      const { response } = error as AxiosError<IErrorResponse>;
      if (
        !(
          response?.data.statusCode === 400 &&
          response?.data.message === 'INVALID_REFRESH_TOKEN'
        )
      ) {
        toast(getErrorMessage(response?.data));
      }
    }
    throw error?.response?.data;
  }
}
