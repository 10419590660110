import React, { memo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ToastMessage } from '../../../../utils/constants';
import CourseForm from '../../Form';
import {
  getCourseErrorsSelector,
  getLoadingCourseSelector,
} from '../../selector';
import { createCourse, ICourseCreateParams } from '../../service';
import { ICourseForRequest, IUpdateCourse, PreviewType } from '../../types';

const CourseCreatePage = memo(() => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoadingCourseSelector);
  const errors = useAppSelector(getCourseErrorsSelector);

  const createCourseAsync = useCallback(
    async (course: ICourseForRequest) => {
      dispatch(createCourse(course)).then(async (response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const newId = (response?.payload as ICourseCreateParams)?.id!;
          window.history.pushState(null, '', `${APP_ROUTES.COURSES}/${newId}`);
          window.dispatchEvent(new Event('popstate'));
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
  }, [errors, createCourseAsync]);

  const onSubmitCreate = async (values: IUpdateCourse) => {
    const data = values;
    if (
      data.name &&
      data.nameRu &&
      data.description &&
      data.description &&
      data.shortDescription &&
      data.shortDescriptionRu &&
      data.level?.length
    ) {
      const level = data.level?.length ? data.level : [];
      let levelRef: string[] = [];
      if (level.length) {
        levelRef = level.map((item) => item.value);
      }
      const position = data.position ? Number(data.position) : 0;
      const shortDescription = data.shortDescription
        ? data.shortDescription
        : '';
      const shortDescriptionRu = data.shortDescriptionRu
        ? data.shortDescriptionRu
        : '';
      const preview = data.previewOption
        ? data.previewOption
        : { label: PreviewType.IMAGE, value: PreviewType.IMAGE };
      let previewRef = '';
      if (preview) {
        previewRef = preview.value;
      }
      const previewImage = data.previewImage?.length
        ? data.previewImage[0]
        : undefined;
      const previewVideo = data.previewVideo?.length
        ? data.previewVideo[0]
        : undefined;
      const image = data.image?.length ? data.image[0] : undefined;
      await createCourseAsync({
        name: data.name,
        nameRu: data.nameRu,
        description: data.description,
        descriptionRu: data.descriptionRu,
        isDraft: data.isDraft,
        category: data.category?.value,
        level,
        position,
        shortDescription,
        shortDescriptionRu,
        previewOption: preview,
        previewRef,
        levelRef,
        previewImage,
        previewVideo,
        image,
      });
    } else {
      toast.warning(ToastMessage.CHECK_FORM_FIELDS);
    }
  };

  return (
    <div>
      <h1>Create course</h1>
      <CourseForm type="create" onSubmit={onSubmitCreate} />
      {isLoading && <p>loading...</p>}
    </div>
  );
});

export default CourseCreatePage;
