import React, { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ToastMessage } from '../../../../utils/constants';
import LessonForm from '../../Form';
import {
  getLessonErrorsSelector,
  getLoadingLessonSelector,
} from '../../selector';
import { createLesson, ICreateLessonParams } from '../../service';
import { ILessonForRequest, IUpdateLesson } from '../../types';
import { PreviewType } from '../../../CoursePage/types';

const LessonCreatePage = memo(() => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoadingLessonSelector);
  const errors = useAppSelector(getLessonErrorsSelector);
  const { courseId } = useParams();

  const createLessonAsync = useCallback(
    async (lesson: ILessonForRequest) => {
      const myCourseId = courseId === undefined ? '' : courseId;
      dispatch(
        createLesson({
          courseId: myCourseId,
          name: lesson.name,
          nameRu: lesson.nameRu,
          description: lesson.description,
          descriptionRu: lesson.descriptionRu,
          position: lesson.position,
          previewOption: lesson.previewOption,
          previewRef: lesson.previewRef,
        }),
      ).then(async (response) => {
        if (response.meta.requestStatus === 'fulfilled') {
          const id = (response?.payload as ICreateLessonParams)?.id!;
          window.history.pushState(null, '', `${APP_ROUTES.LESSONS}/${id}`);
          window.dispatchEvent(new Event('popstate'));
        }
      });
    },
    [dispatch],
  );

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
  }, [errors, createLessonAsync]);

  const onSubmit = async (values: IUpdateLesson) => {
    const data = values;
    if (data.name && data.description && data.nameRu && data.descriptionRu) {
      const position = data.position ? Number(data.position) : 0;
      const preview = data.previewOption
        ? data.previewOption
        : { label: PreviewType.IMAGE, value: PreviewType.IMAGE };
      let previewRef = '';
      if (preview) {
        previewRef = preview.value;
      }
      const video = data.video?.length ? data.video[0] : undefined;
      const image = data.image?.length ? data.image[0] : undefined;
      const duration = data.videoDuration ? data.videoDuration : 0;
      await createLessonAsync({
        name: data.name,
        nameRu: data.nameRu,
        description: data.description,
        descriptionRu: data.descriptionRu,
        position,
        previewOption: preview,
        previewRef,
        videoDuration: duration,
        image,
        video,
      });
    } else {
      toast.warning(ToastMessage.CHECK_FORM_FIELDS_LESSON);
    }
  };

  return (
    <div>
      <h1>Create lesson</h1>
      <LessonForm type="create" onSubmit={onSubmit} />

      {isLoading && <p>loading...</p>}
    </div>
  );
});

export default LessonCreatePage;
