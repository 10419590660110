import Select from 'react-select';
import React, { FC, memo, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Checkbox,
  FormControlLabel,
  Paper,
  Button,
  TextField,
  useTheme,
} from '@mui/material';
import { CourseFormField } from '../styles';
import {
  CourseCategory,
  CourseLevel,
  ICategory,
  ICourseForm,
  ILevel,
  IPreviewOption,
  MediaFuncEnum,
  PreviewType,
} from '../types';
import { TrainerFormLayout, TrainerFormField } from '../../TrainerPage/styles';

interface ICourseFormComponentProps {
  onSubmit: (data: ICourseForm) => void;
  onChangeFile?: (
    e: FileList | null,
    nameFunc: MediaFuncEnum,
    lang?: string,
  ) => void;
  form?: ICourseForm;
  oldImage?: string;
  oldPreview?: string;
  oldVideoPreview?: string;
  oldVideoPreviewRu?: string;
  type: string;
}

const CourseForm: FC<ICourseFormComponentProps> = memo(
  ({
    onSubmit,
    onChangeFile,
    form,
    type,
    oldPreview,
    oldVideoPreview,
    oldVideoPreviewRu,
    oldImage,
  }) => {
    const {
      handleSubmit,
      register,
      control,
      formState: { isDirty, errors },
    } = useForm<ICourseForm>({
      values: {
        name: form?.name,
        nameRu: form?.nameRu,
        description: form?.description,
        descriptionRu: form?.descriptionRu,
        shortDescription: form?.shortDescription,
        shortDescriptionRu: form?.shortDescriptionRu,
        level: form?.level,
        category: form?.category,
        isDraft: form?.isDraft,
        isNew: form?.isNew,
        isPopular: form?.isPopular,
        position: form?.position,
        previewOption: form?.previewOption,
      },
      mode: 'onChange',
    });

    const theme = useTheme();

    const options = useMemo<ILevel[]>(
      () =>
        Object.values(CourseLevel).map((level) => ({
          label: level,
          value: level,
        })),
      [form?.level],
    );

    const optionsPreview = useMemo<IPreviewOption[]>(
      () =>
        Object.values(PreviewType).map((level) => ({
          label: level,
          value: level,
        })),
      [form?.previewOption],
    );

    const optionsCategory = useMemo<ICategory[]>(
      () =>
        Object.values(CourseCategory).map((category) => ({
          label: category,
          value: category,
        })),
      [form?.category],
    );

    const statusField = () => (form ? { shrink: true } : {});
    return (
      <Paper
        elevation={3}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          minWidth: 345,
          maxWidth: 600,
        }}
      >
        <TrainerFormLayout onSubmit={handleSubmit(onSubmit)}>
          <TrainerFormField>
            <TextField
              label="Name"
              fullWidth
              {...register('name', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Name Ru"
              fullWidth
              {...register('nameRu', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.nameRu}
              helperText={errors.nameRu?.message}
            />
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Description"
              fullWidth
              multiline
              {...register('description', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
          </TrainerFormField>

          <TrainerFormField>
            <TextField
              label="Description Ru"
              fullWidth
              multiline
              {...register('descriptionRu', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.descriptionRu}
              helperText={errors.descriptionRu?.message}
            />
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Short description"
              fullWidth
              multiline
              {...register('shortDescription', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.shortDescription}
              helperText={errors.shortDescription?.message}
            />
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Short description Ru"
              fullWidth
              multiline
              {...register('shortDescriptionRu', {
                required: 'This field is required',
              })}
              InputLabelProps={statusField()}
              error={!!errors.shortDescriptionRu}
              helperText={errors.shortDescriptionRu?.message}
            />
          </TrainerFormField>
          <TrainerFormField>
            <TextField
              label="Position"
              fullWidth
              type="number"
              {...register('position', {
                min: 1,
                required: 'This field is required',
              })}
              InputProps={{ inputProps: { min: 1 } }}
              InputLabelProps={statusField()}
              error={!!errors.position}
              helperText={errors.position?.message}
            />
          </TrainerFormField>
          <CourseFormField>
            <label htmlFor="level">Level:</label>
            <Controller
              control={control}
              name="level"
              render={({ field }) => (
                <Select {...field} isMulti options={options} />
              )}
            />
            <div>{errors.level && errors.level.message}</div>
          </CourseFormField>
          <CourseFormField>
            <label htmlFor="category">Category:</label>
            <Controller
              control={control}
              name="category"
              rules={{ required: 'This field is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={optionsCategory}
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      ...(errors.category && {
                        borderColor: theme.palette.error.main,
                      }),
                    }),
                  }}
                />
              )}
            />
            <div
              className={theme.components?.MuiTextField?.styleOverrides?.root?.toLocaleString()}
              style={{ color: theme.palette.error.main }}
            >
              {errors.category && errors.category.message}
            </div>
          </CourseFormField>
          {type === 'update' && (
            <CourseFormField>
              <label htmlFor="previewOption">Preview option:</label>
              <Controller
                control={control}
                name="previewOption"
                render={({ field }) => (
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    {...field}
                    options={optionsPreview}
                  />
                )}
              />
              <div>{errors.previewOption && errors.previewOption.message}</div>
            </CourseFormField>
          )}
          {type === 'update' && (
            <CourseFormField>
              {oldImage && (
                <img src={oldImage} alt="course-img" style={{ width: '30%' }} />
              )}
              <label htmlFor="image">Course image:</label>
              <TextField
                fullWidth
                type="file"
                name="image"
                inputProps={{ accept: 'image/jpeg, image/png' }}
                onChange={(e) => {
                  if (onChangeFile) {
                    onChangeFile(
                      (e.target as HTMLInputElement).files,
                      MediaFuncEnum.image,
                    );
                  }
                }}
              />
            </CourseFormField>
          )}
          {type === 'update' && (
            <CourseFormField>
              {oldPreview && (
                <img
                  src={oldPreview}
                  alt="course-preview-img"
                  style={{ width: '30%' }}
                />
              )}
              <label htmlFor="previewImage">Course preview:</label>
              <TextField
                fullWidth
                type="file"
                name="previewImage"
                inputProps={{ accept: 'image/jpeg, image/png' }}
                onChange={(e) => {
                  if (onChangeFile) {
                    onChangeFile(
                      (e.target as HTMLInputElement).files,
                      MediaFuncEnum.preview,
                    );
                  }
                }}
              />
            </CourseFormField>
          )}
          {type === 'update' && (
            <CourseFormField>
              <FormControlLabel
                {...register('isDraft')}
                control={<Checkbox defaultChecked={form?.isDraft} />}
                label="Is draft"
              />
              <FormControlLabel
                {...register('isNew')}
                control={<Checkbox defaultChecked={form?.isNew} />}
                label="Is New"
              />
              <FormControlLabel
                {...register('isPopular')}
                control={<Checkbox defaultChecked={form?.isPopular} />}
                label="Is Popular"
              />
            </CourseFormField>
          )}
          {type === 'update' && (
            <>
              <CourseFormField>
                {oldVideoPreview && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    src={oldVideoPreview}
                    style={{ width: '320', height: '240' }}
                    controls
                  />
                )}
                <label htmlFor="previewVideo">Course video preview:</label>
                <TextField
                  fullWidth
                  type="file"
                  name="previewVideo"
                  inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
                  onChange={(e) => {
                    if (onChangeFile) {
                      onChangeFile(
                        (e.target as HTMLInputElement).files,
                        MediaFuncEnum.previewVideo,
                      );
                    }
                  }}
                />
              </CourseFormField>

              <CourseFormField>
                {oldVideoPreviewRu && (
                  // eslint-disable-next-line jsx-a11y/media-has-caption
                  <video
                    src={oldVideoPreviewRu}
                    style={{ width: '320', height: '240' }}
                    controls
                  />
                )}
                <label htmlFor="previewVideo">Course video preview Ru:</label>
                <TextField
                  fullWidth
                  type="file"
                  name="previewVideoRu"
                  inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
                  onChange={(e) => {
                    if (onChangeFile) {
                      onChangeFile(
                        (e.target as HTMLInputElement).files,
                        MediaFuncEnum.previewVideo,
                        'ru',
                      );
                    }
                  }}
                />
              </CourseFormField>
            </>
          )}
          {form?.trainer && type === 'update' && (
            <>
              <CourseFormField>
                <p>
                  Trainer name:
                  {form?.trainer}
                </p>
              </CourseFormField>
              <CourseFormField>
                <p>
                  Trainer name:
                  {form?.trainerRu}
                </p>
              </CourseFormField>
            </>
          )}
          {form ? (
            <Button
              size="medium"
              variant="contained"
              aria-label="Update course"
              type="submit"
              title="Update course"
            >
              {' '}
              Update course
            </Button>
          ) : (
            <Button
              size="medium"
              variant="contained"
              disabled={!isDirty}
              aria-label="Create course"
              type="submit"
              title="Create course"
            >
              {' '}
              Create course
            </Button>
          )}
        </TrainerFormLayout>
      </Paper>
    );
  },
);

export default CourseForm;
