import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ToastMessage } from '../../../utils/constants';
import { IChallengeDay } from '../../Challenges/types';
import {
  createChallengeDay,
  getChallengeDay,
  getChallengeDays,
  updateChallengeDay,
  uploadImage,
  uploadVideo,
} from '../service';
import { IUploadFileResponse } from '../../ChallengesPage/types';

export interface IChallengeState {
  isLoading: boolean;
  isImageLoading: boolean;
  isVideoLoading: boolean;
  challengeDays: IChallengeDay[];
  day: IChallengeDay | null;
  imageUrl: string;
  videoUrl: string;
  videoUrlRu: string;
}

const initialState: IChallengeState = {
  isLoading: false,
  challengeDays: [],
  day: null,
  imageUrl: '',
  videoUrl: '',
  videoUrlRu: '',
  isImageLoading: false,
  isVideoLoading: false,
};

export const challengeSlice = createSlice({
  name: 'challengeDay',
  initialState,
  reducers: {
    clearDay: (state) => {
      state.day = null;
      state.imageUrl = '';
      state.videoUrl = '';
      state.videoUrlRu = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getChallengeDays.fulfilled,
      (state, { payload }: PayloadAction<IChallengeDay[]>) => {
        state.challengeDays = payload;
        state.isLoading = false;
      },
    );
    builder.addCase(getChallengeDays.rejected, (state) => {
      toast.error(ToastMessage.GET_CHALLENGE_DAYS_ERROR);
      state.isLoading = false;
    });
    builder.addCase(getChallengeDays.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getChallengeDay.fulfilled,
      (state, { payload }: PayloadAction<IChallengeDay>) => {
        state.day = payload;
        state.imageUrl =
          payload.image && payload.image.url ? payload.image.url : '';
        state.videoUrl =
          payload.video && payload.video.url ? payload.video.url : '';
        state.videoUrlRu =
          payload.videoRu && payload.videoRu.url ? payload.videoRu.url : '';
        state.isLoading = false;
      },
    );
    builder.addCase(getChallengeDay.rejected, (state) => {
      toast.error(ToastMessage.GET_CHALLENGE_DAY_ERROR);
      state.isLoading = false;
    });
    builder.addCase(getChallengeDay.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      createChallengeDay.fulfilled,
      (state, { payload }: PayloadAction<IChallengeDay>) => {
        toast.success(ToastMessage.CREATE_CHALLENGE_DAY_SUCCESS);
        state.isLoading = false;
        state.day = payload;
      },
    );
    builder.addCase(createChallengeDay.rejected, (state) => {
      toast.error(ToastMessage.CREATE_CHALLENGE_DAY_ERROR);
      state.isLoading = false;
    });
    builder.addCase(createChallengeDay.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      uploadImage.fulfilled,
      (state, { payload }: PayloadAction<IUploadFileResponse>) => {
        if (payload.url) {
          state.imageUrl = payload.url;
          toast.success(ToastMessage.UPLOAD_CHALLENGE_IMAGE_SUCCESS);
        }
        state.isImageLoading = false;
      },
    );
    builder.addCase(uploadImage.rejected, (state) => {
      toast.error(ToastMessage.UPLOAD_CHALLENGE_IMAGE_ERROR);
      state.isImageLoading = false;
    });
    builder.addCase(uploadImage.pending, (state) => {
      state.isImageLoading = true;
    });
    builder.addCase(
      uploadVideo.fulfilled,
      (state, { payload }: PayloadAction<IUploadFileResponse>) => {
        if (payload.url) {
          state.videoUrl = payload.url;
          toast.success(ToastMessage.UPLOAD_VIDEO_SUCCESS);
        }
        state.isVideoLoading = false;
      },
    );
    builder.addCase(uploadVideo.rejected, (state) => {
      toast.error(ToastMessage.UPLOAD_VIDEO_ERROR);
      state.isVideoLoading = false;
    });
    builder.addCase(uploadVideo.pending, (state) => {
      state.isVideoLoading = true;
    });
    builder.addCase(updateChallengeDay.fulfilled, (state) => {
      toast.success(ToastMessage.UPDATE_CHALLENGE_DAY_SUCCESS);
      state.isLoading = false;
    });
    builder.addCase(updateChallengeDay.rejected, (state) => {
      toast.error(ToastMessage.UPDATE_CHALLENGE_DAY_ERROR);
      state.isLoading = false;
    });
    builder.addCase(updateChallengeDay.pending, (state) => {
      state.isLoading = true;
    });
  },
});

export const { clearDay } = challengeSlice.actions;
export default challengeSlice.reducer;
