export interface ITokenResponse {
  accessToken: string;
  refreshToken: string;
}

export interface IErrorResponse {
  message: string | string[];
  statusCode: number;
}

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export enum Role {
  SUPER_ADMIN = 'super_admin',
  ADMIN = 'admin',
  USER = 'user',
}

export interface IUser {
  email: string;
  roles: Role[];
}

export interface ICurrentUserResponse extends IUser {}

export interface IListResponse<T> {
  list: T[];
  count: number;
}

export interface IRefreshTokenRequest {
  refreshToken: string;
}

export interface IListRequest {
  page: number;
  pageSize: number;
}

export interface IAvatar {
  id: string;
  path: string;
  size?: number | null;
  url?: string | null;
}

export interface IUserProfile {
  id: string;
  firstName?: string | null;
  birthday?: Date | null;
  avatar: IAvatar;
}

export interface IUserResponse {
  id: string;
  email: string;
  isVerified: boolean;
  code: number;
  roles: Role[];
  profile: IUserProfile;
  lastLogin: Date | null;
}

export interface IImage {
  id: string;
  path: string;
  size: number | null;
  url: string;
  thumbnailUrl: string;
}

export interface IUpdateImageResponse
  extends Pick<IImage, 'url' | 'thumbnailUrl'> {}

export interface IMediaUpdateRequest {
  id: string;
  file: File;
  duration?: number;
  lang?: string;
}

export interface IVideo extends Omit<IImage, 'thumbnailUrl'> {
  duration: number;
}
