import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Form from '../Form';
import { IAchievementForm } from '../../types';
import { ToastMessage } from '../../../../utils/constants';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import BackLink from '../../../../components/BackLink';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { createAchievement, uploadImage } from '../../service';
import { getAchievementsLoadingSelector } from '../../selector';
import { IAchievement } from '../../../Achievements/types';

const CreateAchievement = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getAchievementsLoadingSelector);
  const navigate = useNavigate();
  const goBack = () => navigate(APP_ROUTES.ACHIEVEMENTS);

  const onSubmit = ({
    description,
    files,
    name,
    nameRu,
    descriptionRu,
  }: IAchievementForm) => {
    if (
      description &&
      files &&
      files.length &&
      name &&
      nameRu &&
      descriptionRu
    ) {
      const file = files[0];
      dispatch(
        createAchievement({
          name,
          nameRu,
          description,
          descriptionRu,
        }),
      ).then(({ meta, payload }) => {
        if (meta.requestStatus === 'fulfilled') {
          const { id } = payload as IAchievement;
          if (id) {
            dispatch(
              uploadImage({
                file,
                id,
              }),
            );
          }
        }
      });
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  return (
    <div>
      <BackLink text="Back to Achievements" onClick={goBack} />
      <h1>New achievement</h1>
      {isLoading ? <p>Loading...</p> : <Form onSubmit={onSubmit} />}
    </div>
  );
};

export default CreateAchievement;
