import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextField } from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { IDayForm } from '../../types';
import {
  StyledPaper,
  FormLayout,
  StyledImg,
} from '../../../ChallengesPage/styled';
import { StyledVideo } from '../../styled';

interface IFromProps {
  form?: IDayForm;
  onSubmit: (data: IDayForm) => void;
  oldImage?: string;
  oldVideoPreview?: string;
  oldVideoPreviewRu?: string;
  position?: number | string;
}

const Form = memo(
  ({
    form,
    onSubmit,
    position,
    oldImage,
    oldVideoPreview,
    oldVideoPreviewRu,
  }: IFromProps) => {
    const {
      handleSubmit,
      register,
      formState: { errors, isDirty, isValid },
      watch,
    } = useForm<IDayForm>({
      values: {
        name: form?.name || '',
        nameRu: form?.nameRu || '',
        description: form?.description || '',
        descriptionRu: form?.descriptionRu || '',
        duration: form?.duration || 0,
        durationRu: form?.durationRu || 0,
        image: form?.image,
        video: form?.video,
        videoRu: form?.videoRu,
      },
    });
    const imageFiles = watch('image');
    const videoFiles = watch('video');
    const videoFilesRu = watch('videoRu');
    const statusField = () => (form ? { shrink: true } : {});

    return (
      <StyledPaper elevation={3}>
        <FormLayout onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextField
              label="Name"
              fullWidth
              {...register('name')}
              InputLabelProps={statusField()}
            />
            <span>{errors.name && errors.name.message}</span>
          </div>
          <div>
            <TextField
              label="Name Ru"
              fullWidth
              {...register('nameRu')}
              InputLabelProps={statusField()}
            />
            <span>{errors.nameRu && errors.nameRu.message}</span>
          </div>
          <div>
            <TextField
              label="Description"
              fullWidth
              multiline
              {...register('description')}
              InputLabelProps={statusField()}
            />
            <span>{errors.description && errors.description.message}</span>
          </div>
          <div>
            <TextField
              label="Description Ru"
              fullWidth
              multiline
              {...register('descriptionRu')}
              InputLabelProps={statusField()}
            />
            <span>{errors.descriptionRu && errors.descriptionRu.message}</span>
          </div>

          <div>
            <label htmlFor="file">Taks Image</label>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: 'image/jpeg, image/png' }}
              {...register('image')}
            />
            <span>{errors.image && errors.image.message}</span>
            {oldImage && !imageFiles?.length ? (
              <StyledImg src={oldImage} alt="" />
            ) : null}
          </div>
          <div>
            <TextField
              label="Video Duration"
              fullWidth
              type="number"
              {...register('duration')}
              InputLabelProps={statusField()}
            />
            <span>{errors.duration && errors.duration.message}</span>
          </div>
          <div>
            <label htmlFor="previewVideo">Task Video</label>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
              {...register('video')}
            />
            <span>{errors.video && errors.video.message}</span>
            {oldVideoPreview && !videoFiles?.length && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <StyledVideo src={oldVideoPreview} controls />
            )}
          </div>

          <div>
            <TextField
              label="Video Duration Ru"
              fullWidth
              type="number"
              {...register('durationRu')}
              InputLabelProps={statusField()}
            />
            <span>{errors.durationRu && errors.durationRu.message}</span>
          </div>
          <div>
            <label htmlFor="previewVideoRu">Task Video Ru</label>
            <TextField
              fullWidth
              type="file"
              inputProps={{ accept: '.mp4, .avi, .mpeg, .mov' }}
              {...register('videoRu')}
            />
            <span>{errors.videoRu && errors.videoRu.message}</span>
            {oldVideoPreviewRu && !videoFilesRu?.length && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <StyledVideo src={oldVideoPreviewRu} controls />
            )}
          </div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isDirty || !isValid}
          >
            {form ? `Update Day ${position}` : `Create Day ${position}`}
          </Button>
        </FormLayout>
      </StyledPaper>
    );
  },
);

export default Form;
