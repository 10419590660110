import React, { FC, memo } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ToastMessage } from '../../../../utils/constants';
import TrainerForm from '../../components/Form';
import { getLoadingTrainerSelector } from '../../selectors';
import { createTrainer } from '../../service';
import { ICreateTrainerSubmit } from '../../types';

const TrainerCreatePage: FC = memo(() => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(getLoadingTrainerSelector);

  const onSubmit = async (values: ICreateTrainerSubmit) => {
    if (
      values.name &&
      values.nameRu &&
      values.position &&
      values.positionRu &&
      values.description &&
      values.descriptionRu &&
      values.courses?.length &&
      values.file?.length
    ) {
      const file = values.file[0];
      const coursesIds = values.courses.map((course) => course.value);
      const coursesTrainer = coursesIds ? coursesIds.join(',') : '';
      await dispatch(
        createTrainer({
          name: values.name,
          nameRu: values.nameRu,
          description: values.description,
          descriptionRu: values.descriptionRu,
          position: values.position,
          positionRu: values.positionRu,
          file,
          courses: coursesTrainer,
        }),
      );
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  return (
    <div>
      <h1>Create page</h1>
      {isLoading ? <p>Loading...</p> : <TrainerForm onSubmit={onSubmit} />}
    </div>
  );
});

export default TrainerCreatePage;
