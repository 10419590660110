import React, { FC, memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import TrainerForm from '../../components/Form';
import { getLoadingTrainerSelector, getTrainerSelector } from '../../selectors';
import { getTrainer, updateTrainer } from '../../service';
import { ICreateTrainerSubmit } from '../../types';

const TrainerUpdatePage: FC = memo(() => {
  const dispatch = useAppDispatch();
  const trainer = useAppSelector(getTrainerSelector);
  const isLoading = useAppSelector(getLoadingTrainerSelector);
  const { id } = useParams();

  const onSubmit = async (values: ICreateTrainerSubmit) => {
    const file = values.file?.length ? values.file[0] : undefined;
    const coursesIds = values.courses?.length
      ? values.courses?.map((course) => course.value)
      : undefined;
    const coursesTrainer = coursesIds ? coursesIds.join(',') : '';
    if (id) {
      await dispatch(
        updateTrainer({
          id,
          name: values.name,
          nameRu: values.nameRu,
          description: values.description,
          descriptionRu: values.descriptionRu,
          position: values.position,
          positionRu: values.positionRu,
          file,
          courses: coursesTrainer,
        }),
      );
    }
  };

  const getTrainerAsync = async () => {
    if (id) await dispatch(getTrainer({ id }));
  };

  useEffect(() => {
    getTrainerAsync();
  }, []);

  return (
    <div>
      <h1>Update page</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <TrainerForm
          onSubmit={onSubmit}
          form={{
            name: trainer?.name,
            nameRu: trainer?.nameRu,
            description: trainer?.description,
            descriptionRu: trainer?.descriptionRu,
            position: trainer?.position,
            positionRu: trainer?.positionRu,
            courses: trainer?.courses.map((course) => ({
              label: course.name,
              value: course.id,
            })),
          }}
          oldAvatar={trainer?.avatar.url}
        />
      )}
    </div>
  );
});

export default TrainerUpdatePage;
