import axios, { AxiosHeaders, CreateAxiosDefaults } from 'axios';
import Cookies from 'js-cookie';
import { refreshAccessToken } from './refreshToken';
import { COOKIE_ACCESS_TOKEN_NAME } from '../../utils/constants';
import configApp from '../../config';

const axiosApiInstance = axios.create({
  baseURL: configApp.restApiHost,
} as CreateAxiosDefaults);

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    const accessToken = Cookies.get('accessToken');
    if (accessToken) {
      (config.headers as AxiosHeaders).set(
        'Authorization',
        `Bearer ${accessToken}`,
      );

      if (!config.headers['x-lang']) {
        (config.headers as AxiosHeaders).set('x-lang', 'all');
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error.response.data.message === 'INVALID_ACCESS_TOKEN' &&
      // eslint-disable-next-line no-underscore-dangle
      !originalRequest._retry
    ) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      const accessToken = await refreshAccessToken();
      if (accessToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${COOKIE_ACCESS_TOKEN_NAME}`;
        return axiosApiInstance(originalRequest);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
