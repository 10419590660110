import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../Form';
import { IAchievementForm } from '../../types';
import { ToastMessage } from '../../../../utils/constants';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import BackLink from '../../../../components/BackLink';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  getAchievementsLoadingSelector,
  getAchievementSelector,
  getImageSelector,
} from '../../selector';
import { getAchievement, updateAchievement, uploadImage } from '../../service';
import { clearState } from '../../slices';

const UpdateAchievement = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isLoading = useAppSelector(getAchievementsLoadingSelector);
  const achievement = useAppSelector(getAchievementSelector);
  const image = useAppSelector(getImageSelector);
  const navigate = useNavigate();
  const goBack = () => navigate(APP_ROUTES.ACHIEVEMENTS);

  useEffect(() => {
    if (id) {
      dispatch(getAchievement({ id }));
    }
    return () => {
      dispatch(clearState());
    };
  }, []);

  const onSubmit = ({
    description,
    files,
    name,
    nameRu,
    descriptionRu,
  }: IAchievementForm) => {
    const isFileAttached =
      (files && files.length) || (achievement?.image && achievement?.image.url);
    if (
      description &&
      name &&
      nameRu &&
      descriptionRu &&
      isFileAttached &&
      id
    ) {
      dispatch(
        updateAchievement({
          name,
          nameRu,
          description,
          descriptionRu,
          id,
        }),
      );
      if (files && files.length) {
        const file = files[0];
        dispatch(
          uploadImage({
            file,
            id,
          }),
        );
      }
    } else {
      toast.warning(ToastMessage.CHECK_ALL_FIELDS);
    }
  };

  return (
    <div>
      <BackLink text="Back to Achievements" onClick={goBack} />
      <h1>Update achievement</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <Form
          onSubmit={onSubmit}
          form={{
            name: achievement?.name,
            nameRu: achievement?.nameRu,
            description: achievement?.description,
            descriptionRu: achievement?.descriptionRu,
          }}
          oldImage={image}
        />
      )}
    </div>
  );
};

export default UpdateAchievement;
