import React, { memo, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { APP_ROUTES } from '../../../../core/router/appRoutes';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import CourseForm from '../../Form';
import LessonList from '../../LessonList';
import {
  getCourseErrorsSelector,
  getCourseSelector,
  getLoadingCourseSelector,
} from '../../selector';
import {
  getCourse,
  updateCourse,
  updateCourseImage,
  updateCoursePreview,
  updateCourseVideo,
} from '../../service';
import {
  CourseLevel,
  ICourseForRequest,
  IUpdateCourse,
  MediaFuncEnum,
  PreviewType,
} from '../../types';

const CourseUpdatePage = memo(() => {
  const dispatch = useAppDispatch();
  const course = useAppSelector(getCourseSelector);
  const isLoading = useAppSelector(getLoadingCourseSelector);
  const errors = useAppSelector(getCourseErrorsSelector);
  const { id } = useParams();

  const getCourseAsync = async () => {
    if (id) {
      await dispatch(getCourse({ id }));
    }
  };

  const updateCourseAsync = useCallback(
    async (courseUpdate: ICourseForRequest) => {
      if (id) {
        await dispatch(updateCourse({ ...courseUpdate, id }));
      }
    },
    [dispatch],
  );

  const updateCoursePreviewAsync = async (courseId: string, file: File) => {
    if (courseId) {
      await dispatch(updateCoursePreview({ id: courseId, file }));
    }
  };

  const updateCourseVideoAsync = async (
    courseId: string,
    file: File,
    lang?: string,
  ) => {
    if (courseId) {
      await dispatch(updateCourseVideo({ id: courseId, file, lang }));
    }
  };

  const updateCourseImageAsync = async (courseId: string, file: File) => {
    if (courseId) {
      await dispatch(updateCourseImage({ id: courseId, file }));
    }
  };

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e: string) => {
        toast.error(e);
      });
    }
    getCourseAsync();
  }, [errors, updateCourseAsync]);

  const onSubmitUpdate = (values: IUpdateCourse) => {
    const data = values;
    if (
      id &&
      data.name &&
      data.nameRu &&
      data.description &&
      data.descriptionRu &&
      data.isDraft !== undefined
    ) {
      const level = data.level?.length ? data.level : [];
      let levelRef: string[] = [];
      if (level.length) {
        levelRef = level.map((item) => item.value);
      }
      const position = data.position ? Number(data.position) : 0;
      const shortDescription = data.shortDescription
        ? data.shortDescription
        : '';
      const shortDescriptionRu = data.shortDescriptionRu
        ? data.shortDescriptionRu
        : '';
      const preview = data.previewOption
        ? data.previewOption
        : { label: PreviewType.IMAGE, value: PreviewType.IMAGE };
      let previewRef = '';
      if (preview) {
        previewRef = preview.value;
      }
      updateCourseAsync({
        name: data.name,
        nameRu: data.nameRu,
        description: data.description,
        descriptionRu: data.descriptionRu,
        isDraft: data.isDraft,
        isNew: data.isNew,
        isPopular: data.isPopular,
        category: data.category?.value,
        level,
        position,
        shortDescription,
        shortDescriptionRu,
        previewOption: preview,
        previewRef,
        levelRef,
      });
    }
  };

  const onChangeFile = async (
    event: FileList | null,
    nameFunc: MediaFuncEnum,
    lang?: string,
  ) => {
    if (event && id) {
      const file = event[0] ? event[0] : null;
      if (file) {
        switch (nameFunc) {
          case MediaFuncEnum.preview: {
            await updateCoursePreviewAsync(id, file);
            break;
          }
          case MediaFuncEnum.image: {
            await updateCourseImageAsync(id, file);
            break;
          }
          case MediaFuncEnum.previewVideo: {
            await updateCourseVideoAsync(id, file, lang);
            break;
          }
          default:
            break;
        }
        await getCourseAsync();
      }
    }
  };

  return (
    <div>
      <h1>Update course</h1>
      {course && (
        <CourseForm
          onSubmit={onSubmitUpdate}
          onChangeFile={onChangeFile}
          type="update"
          form={{
            name: course?.name,
            nameRu: course?.nameRu,
            description: course?.description,
            descriptionRu: course?.descriptionRu,
            shortDescription: course?.shortDescription,
            shortDescriptionRu: course?.shortDescriptionRu,
            level: course?.level?.map((level: CourseLevel) => ({
              label: level,
              value: level,
            })),
            category: { label: course?.category, value: course?.category },
            isDraft: course?.isDraft,
            isNew: course?.isNew,
            isPopular: course?.isDraft,
            position: course?.position,
            previewOption: {
              label: course?.previewOption,
              value: course?.previewOption,
            },
            trainer: course?.trainer?.name,
            trainerRu: course?.trainer?.nameRu,
          }}
          oldImage={course?.image?.url}
          oldPreview={course?.coursePreview?.url}
          oldVideoPreview={course?.courseVideoPreview?.url}
          oldVideoPreviewRu={course?.courseVideoPreviewRu?.url}
        />
      )}
      {isLoading && <p>loading...</p>}

      <div style={{ margin: '30px 0 0 0px' }}>
        {course?.lessons?.length ? (
          <LessonList lessons={course?.lessons} />
        ) : (
          <h3>No lessons</h3>
        )}
        <Link
          style={{ margin: '30px 0 30px 0px' }}
          to={`${APP_ROUTES.COURSES}/${id}${APP_ROUTES.LESSONS}/create`}
        >
          <Button size="medium" variant="contained">
            Create lesson
          </Button>
        </Link>
      </div>
    </div>
  );
});

export default CourseUpdatePage;
