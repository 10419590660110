import React, { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { ToastMessage } from '../../../../utils/constants';
import { MediaFuncEnum, PreviewType } from '../../../CoursePage/types';
import LessonForm from '../../Form';
import {
  getIsVideoUploadingLessonSelector,
  getLessonErrorsSelector,
  getLessonSelector,
  getLoadingLessonSelector,
} from '../../selector';
import {
  getLesson,
  updateLesson,
  updateLessonImage,
  updateLessonVideo,
} from '../../service';
import { ILessonForRequest, IUpdateLesson } from '../../types';

const LessonUpdatePage = memo(() => {
  const dispatch = useAppDispatch();
  const lesson = useAppSelector(getLessonSelector);
  const isLoading = useAppSelector(getLoadingLessonSelector);
  const isVideoUploading = useAppSelector(getIsVideoUploadingLessonSelector);
  const errors = useAppSelector(getLessonErrorsSelector);
  const { id } = useParams();

  const getLessonAsync = async () => {
    if (id) {
      dispatch(getLesson({ id }));
    }
  };

  const updateLessonAsync = useCallback(
    async (lessonUpdate: ILessonForRequest) => {
      if (id) {
        await dispatch(updateLesson({ ...lessonUpdate, id }));
      }
    },
    [dispatch],
  );

  const updateLessonVideoAsync = async (
    file: File,
    duration: number,
    lang?: string,
  ) => {
    if (id) {
      await dispatch(updateLessonVideo({ id, file, duration, lang }));
    }
  };

  const updateLessonImageAsync = async (file: File) => {
    if (id) {
      await dispatch(updateLessonImage({ id, file }));
    }
  };

  useEffect(() => {
    if (errors.length) {
      errors.forEach((e) => {
        toast.error(e);
      });
    }
    getLessonAsync();
  }, [errors]);

  const onSubmit = async (values: IUpdateLesson) => {
    const data = values;
    if (
      id &&
      data.name &&
      data.description &&
      data.nameRu &&
      data.descriptionRu
    ) {
      const position = data.position ? Number(data.position) : 0;
      const preview = data.previewOption
        ? data.previewOption
        : { label: PreviewType.IMAGE, value: PreviewType.IMAGE };
      let previewRef = '';
      if (preview) {
        previewRef = preview.value;
      }
      await updateLessonAsync({
        name: data.name,
        nameRu: data.nameRu,
        description: data.description,
        descriptionRu: data.descriptionRu,
        position,
        previewOption: preview,
        previewRef,
        videoDuration: data.videoDuration && +data.videoDuration,
        videoDurationRu: data.videoDurationRu && +data.videoDurationRu,
      });
    }
  };

  const onChangeFile = async (
    event: FileList | null,
    nameFunc: MediaFuncEnum,
    duration?: number,
    lang?: string,
  ) => {
    if (event) {
      const file = event[0] ? event[0] : null;
      if (file) {
        switch (nameFunc) {
          case MediaFuncEnum.image: {
            await updateLessonImageAsync(file);
            break;
          }
          case MediaFuncEnum.video: {
            if (duration && duration >= 0) {
              await updateLessonVideoAsync(file, duration, lang);
            } else {
              toast.warning(ToastMessage.VIDEO_ERROR);
            }
            break;
          }
          default:
            break;
        }
        await getLessonAsync();
      }
    }
  };

  return (
    <div>
      <h1>Update lesson</h1>
      {lesson && (
        <LessonForm
          onSubmit={onSubmit}
          onChangeFile={onChangeFile}
          type="update"
          form={{
            name: lesson?.name,
            nameRu: lesson?.nameRu,
            description: lesson?.description,
            descriptionRu: lesson?.descriptionRu,
            position: lesson?.position,
            previewOption: {
              label: lesson?.previewOption,
              value: lesson?.previewOption,
            },
            videoDuration: lesson?.duration,
            videoDurationRu: lesson?.durationRu,
          }}
          oldImage={lesson?.image?.url}
          oldVideo={lesson?.video?.url}
          oldVideoRu={lesson?.videoRu?.url}
        />
      )}

      {isLoading && <p>loading...</p>}
      {isVideoUploading && <p>video uploading...</p>}
    </div>
  );
});

export default LessonUpdatePage;
