import axios, { AxiosError, CreateAxiosDefaults, ResponseType } from 'axios';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import config from '../../config';
import { getErrorMessage } from '../../utils/helpers';
import { IErrorResponse } from '../../types';

interface IPatchOptions {
  path: string;
  data: { [key: string]: any };
}

interface IAxiosResponse {
  [key: string]: any;
}

export default async function patch(
  options: IPatchOptions,
  contentType: string = 'application/json',
  responseType: ResponseType = 'json',
  params?: any,
): Promise<any> {
  const { path, data = {} } = options;
  const axiosInstance = axios.create({
    baseURL: config.restApiHost,
    headers: {
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
      patch: {
        'content-type': contentType,
      },
      'x-lang': 'all',
    },
    responseType,
    params,
  } as CreateAxiosDefaults);
  try {
    const res: IAxiosResponse = await axiosInstance.patch<IAxiosResponse>(
      path,
      data,
    );
    if (res.data.error) {
      throw res.data.error;
    }
    return res.data;
  } catch (error: any) {
    if (error?.response?.data) {
      const { response } = error as AxiosError<IErrorResponse>;
      toast(getErrorMessage(response?.data));
    }
    throw error?.response?.data;
  }
}
