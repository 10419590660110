import { createAsyncThunk } from '@reduxjs/toolkit';
import { get, patch, post } from '../../../core/axios';
import { API_ROUTES } from '../../../core/router/apiRoutes';
import { IErrorResponse } from '../../../types';
import {
  IGetChallengeRequest,
  IUploadFileRequest,
  IUploadFileResponse,
} from '../../ChallengesPage/types';
import { IChallengeDay } from '../../Challenges/types';
import { ICreateDayRequest, IUploadVideoRequest } from '../types';
import { uploadVideoToS3 } from '../../../utils/api';

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse;
}

export const getChallengeDay = createAsyncThunk<
  IChallengeDay,
  IGetChallengeRequest,
  ICommonChunkConfig
>('challengeDay/getChallengeDay', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CHALLENGES}/challengeDays/${params.id}`,
    });
    return response as IChallengeDay;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const getChallengeDays = createAsyncThunk<
  IChallengeDay[],
  IGetChallengeRequest,
  ICommonChunkConfig
>('challengeDay/getChallengeDays', async (params, { rejectWithValue }) => {
  try {
    const response = await get({
      path: `${API_ROUTES.CHALLENGES}/${params.id}/challengeDays`,
    });
    return response as IChallengeDay[];
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const createChallengeDay = createAsyncThunk<
  IChallengeDay,
  ICreateDayRequest,
  ICommonChunkConfig
>('challengeDay/createChallengeDay', async (params, { rejectWithValue }) => {
  try {
    const { id, ...data } = params;
    const response = await post({
      path: `${API_ROUTES.CHALLENGES}/${params.id}/challengeDays`,
      data,
    });
    return response as IChallengeDay;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const uploadImage = createAsyncThunk<
  IUploadFileResponse,
  IUploadFileRequest,
  ICommonChunkConfig
>('challengeDay/uploadImage', async (params, { rejectWithValue }) => {
  try {
    const { file, id } = params;
    const response = await post(
      {
        path: `${API_ROUTES.CHALLENGES}/challengeDays/${id}/upload-challenge-day-image`,
        data: { file },
      },
      'multipart/form-data',
    );
    return response as IUploadFileResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const uploadVideo = createAsyncThunk<
  IUploadFileResponse,
  IUploadVideoRequest,
  ICommonChunkConfig
>('challengeDay/uploadVideo', async (params, { rejectWithValue }) => {
  try {
    const { file, id, duration } = params;
    const key = await uploadVideoToS3(file);
    const response = await post({
      path: `${API_ROUTES.CHALLENGES}/challengeDays/${id}/add-challenge-day-video`,
      data: { key, duration },
      ...(params.lang && { headers: { 'x-lang': params.lang } }),
    });
    return response as IUploadFileResponse;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});

export const updateChallengeDay = createAsyncThunk<
  ICreateDayRequest,
  ICreateDayRequest,
  ICommonChunkConfig
>('challenge/updateChallengeDay', async (data, { rejectWithValue }) => {
  try {
    const { id, ...challenge } = data;
    await patch({
      path: `${API_ROUTES.CHALLENGES}/challengeDays/${id}`,
      data: challenge,
    });
    return data;
  } catch (error) {
    return rejectWithValue(error as IErrorResponse);
  }
});
